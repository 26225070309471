import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import { get } from "utils/lodash";
import MISNoData from "components/common/MISNoData";
import { capitalizeWords } from "utils/commonFunction";
import { SubTitleTypography } from "utils/members";
import T from "T";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  borderBottom: "none",
  overflow: "hidden",
  fontSize: 14,
  textAlign: "center",
}));
const TrainingHistoryBody = ({ tableRecord = [] }) => {
  return (
    <TableBody>
      {Children.toArray(
        tableRecord.map((record, index) => {
          const { trainingCourse, subsCourses, assignDate, courseDuration, completionDate } = record;
          const courseName = get(trainingCourse, "courseName", "");
          console.log(record, "history");
          const courseType = get(record, "trainingType", "");
          const pendingDays = Math.ceil((new Date(completionDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
          return (
            <TableRow sx={{ background: BACKGROUND.white }}>
              <StyledTableCell>{courseName}</StyledTableCell>
              <StyledTableCell>{courseType}</StyledTableCell>
              <StyledTableCell>
                {courseType !== T.OFFLINE_TRAINING
                  ? Children.toArray(
                      subsCourses.map((data) => {
                        const subsCourseName = get(data, "subCourse.subsCourseName", "");
                        return <SubTitleTypography> {capitalizeWords(subsCourseName)} </SubTitleTypography>;
                      }),
                    )
                  : "N/A"}
              </StyledTableCell>
              <StyledTableCell>{courseDuration}</StyledTableCell>
              <StyledTableCell>{assignDate}</StyledTableCell>
              <StyledTableCell>{completionDate}</StyledTableCell>
              <StyledTableCell>{get(record, "startDate", "-")}</StyledTableCell>
              <StyledTableCell>{get(record, "endDate", "-")}</StyledTableCell>
              <StyledTableCell>{get(record, "reviewDate", "-")}</StyledTableCell>
              <StyledTableCell>{pendingDays < -1 ? "Over" : pendingDays}</StyledTableCell>
            </TableRow>
          );
        }),
      )}

      {tableRecord && tableRecord.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
            <MISNoData />
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

TrainingHistoryBody.propTypes = {
  tableRecord: PropTypes.array,
};

export default TrainingHistoryBody;
